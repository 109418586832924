import React from 'react'

import {
  OfferWrapper,
  OfferTitle,
  Section,
  SectionWrapper,
  ContactMobileImage,
  WhyUsHeader,
  OfferBgWrapper,
  HelpImage,
} from './styles'
import { ButtonLink, YellowButton } from '../../UI/button'

import wsparcieImage from './../../../images/home/icowsparcie.svg'
import BackgroundDesktopImage from '../../../assets/backgroundesktop.svg'
import backgroundMobileImage from '../../../images/home/mobilebg1.svg'
import VisibilitySensor from '../../VisibilitySensor/VisibilitySensor'
import { IdeaImage, PaperImage } from './styles'
import paperImage from '../../../images/home/2.svg'
import uxui from '../../../images/home/uxui.svg'

const HomePageBottomSection = () => {
  return (
    <Section id="home-contact">
      <SectionWrapper>
        <div className="hero-body">
          <div className="container is-fluid why-us-section">
            <div className={'why-us-text'}>
              <WhyUsHeader>WHY US?</WhyUsHeader>
              <p>
                We have 10 years of experience in collaborating with overseas
                and local clients.
              </p>
              <YellowButton to={'/portfolio'}>Our projects</YellowButton>
            </div>
            <div className={'wsparcieImage'}>
              <VisibilitySensor once>
                {({ isVisible }) => (
                  <HelpImage
                    isvisible={isVisible ? 1 : 0}
                    src={wsparcieImage}
                    alt={''}
                    title={'hands icon'}
                  />
                )}
              </VisibilitySensor>
            </div>
          </div>
        </div>
        <OfferWrapper>
          <OfferTitle>What can we do?</OfferTitle>
          <p>
            We're producing first-class software to help companies achieve their
            business goals.
          </p>
          <ButtonLink to={'/offer'}>Offer</ButtonLink>
          <div className={'is-hidden-mobile'}>
            <IdeaImage src={uxui} alt={''} title={'idea icon'} />
            <PaperImage src={paperImage} alt={''} title={'ux/ui icon'} />
          </div>
        </OfferWrapper>
        <OfferBgWrapper>
          <BackgroundDesktopImage />
          <ContactMobileImage src={backgroundMobileImage} alt={''} />
        </OfferBgWrapper>
      </SectionWrapper>
    </Section>
  )
}

export default HomePageBottomSection
