import React from 'react'
import { ComputerWrapper, ComputerAnimatedText, EnterLink } from './styles'
import computerImage from '../../../images/home/future.ai.svg'
import { animated, useTrail } from 'react-spring'

const items = '< Quality apps with coverage! >'.split('')
const config = { mass: 10, tension: 4000, friction: 400 }

const HomePageComputerAnimation = () => {
  const trail = useTrail(items.length, {
    config,
    delay: 800,
    from: { visibility: 'hidden', opacity: 0 },
    to: { visibility: 'visible', opacity: 1 },
  })

  return (
    <ComputerWrapper>
      <img src={computerImage} alt={''} title={'Computer image'} />
      <ComputerAnimatedText>
        {trail.map(({ ...rest }, index) => (
          <animated.div
            key={index}
            style={{
              ...rest,
              display: 'inline',
            }}
          >
            {items[index]}
          </animated.div>
        ))}
      </ComputerAnimatedText>
      <EnterLink to="/#home-contact">Enter</EnterLink>
    </ComputerWrapper>
  )
}

export default HomePageComputerAnimation
