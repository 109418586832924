import React from 'react'
import {
  CircleYellowIco,
  HelloText,
  LikeImage,
  HeaderWrapper,
  DevicesImage,
} from './styles'
import devicesImage from '../../../images/home/computer.ai.svg'
import likeImage from '../../../images/home/thumb.ai.svg'

const HomePageHeader = () => {
  return (
    <>
      <HeaderWrapper>
        <div className="about_us_text" style={{ paddingLeft: '15%' }}>
          <HelloText>
            We are <strong>Deployed</strong>,<br />
            a software development
            <br />
            company that builds <br />
            custom digital products
            <br />
            for our clients.
          </HelloText>
        </div>
        <DevicesImage src={devicesImage} title={'device ico'} />
      </HeaderWrapper>
      <LikeImage src={likeImage} alt={''} title={'thumb up icon'} />
      <CircleYellowIco />
    </>
  )
}

export default HomePageHeader
