import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  mediumBreakpoint,
  largeBreakpoint,
} from '../../../config/css-constants'

export const ComputerWrapper = styled.div`
  max-width: 300px;
  position: absolute;
  color: #fff;
  font-size: 1rem;
  left: -10%;
  top: 5%;
  z-index: 2;
  @media all and (min-width: ${mediumBreakpoint}) {
    left: 7%;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    max-width: 600px;
    font-size: 1.4rem;
  }
`

export const ComputerAnimatedText = styled.span`
  position: absolute;
  left: 30%;
  top: 23%;
  font-size: 0.9rem;
  max-width: 40%;
  @media all and (min-width: ${largeBreakpoint}) {
    font-size: inherit;
    top: 30%;
    left: 34%;
    max-width: 34%;
  }
`

export const EnterLink = styled((props) => <Link {...props} />)`
  color: #397e8d;
  border: 2px solid #f9c249;
  background: #f9c249;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  left: 35%;
  bottom: 29%;
  padding: 1px 5px;
  border-radius: 5px;
  min-width: 80px;
  font-size: 0.8rem;
  text-align: center;
  transition: all 0.4s ease;
  &:hover {
    color: #f9c249;
    background: transparent;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    left: 38%;
    bottom: 30%;
    padding: 5px 20px;
    border-radius: 10px;
    min-width: 120px;
    font-size: 1rem;
  }
`
