import styled from 'styled-components'
import {
  xsmallBreakpoint,
  largeBreakpoint,
} from '../../../config/css-constants'

export const ContactWrapper = styled.div`
  color: #000;
  text-align: center;
  display: block;
  z-index: 2;
  @media all and (min-width: ${xsmallBreakpoint}) {
    margin-top: 10rem;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    font-size: 1.4rem;
  }
`

export const ContactTitle = styled.p`
  font-weight: 400;
`

export const ContactSection = styled.section`
  position: relative;
  line-height: 0;
  margin-top: 5rem;
`

export const ContactSectionWrapper = styled.div`
  line-height: 1.5rem;
`
