import React, { Component } from 'react'

import HomePageTopSection from './HomePageTopSection/HomePageTopSection'
import HomePageBottomSection from './HomePageBottomSection/HomePageBottomSection'
import HomePageContactSection from './HomePageContactSection/HomePageContactSection'

class CustomHomePage extends Component {
  render() {
    return (
      <>
        <HomePageTopSection />
        <HomePageBottomSection />
        <HomePageContactSection />
      </>
    )
  }
}

export default CustomHomePage
