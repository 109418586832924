import React from 'react'
import { DeviceImage, MobileImage, IdeaImage, PaperImage } from './styles'
import devicesImage from '../../../images/home/computer.ai.svg'
import mobileImage from '../../../images/home/phone.ai.svg'
import pomyslImage from '../../../images/home/bulb.svg'
import paperImage from '../../../images/home/paperico.svg'

const HomePageHeaderIcons = () => {
  return (
    <>
      <DeviceImage src={devicesImage} alt={''} title={'computer icon'} />
      <MobileImage src={mobileImage} alt={''} title={'phone icon'} />
      <IdeaImage src={pomyslImage} alt={''} title={'idea icon'} />
      <PaperImage src={paperImage} alt={''} title={'paper icon'} />
    </>
  )
}

export default HomePageHeaderIcons
