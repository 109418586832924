import styled from 'styled-components'
import { fullhd, mediumBreakpoint } from '../../../config/css-constants'
import { scaling } from '../../../assets/keyframes/keyframes'

export const HelloText = styled.h2``

export const CircleYellowIco = styled.div`
  position: absolute;
  background-color: #fec256;
  border-radius: 50%;
  top: 60%;
  left: 5%;
  width: 45px;
  height: 45px;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${mediumBreakpoint}) {
    display: none;
  }
  @media all and (min-width: ${fullhd}) {
    display: block;
    left: unset;
    right: 16%;
    top: 60%;
  }
`

export const LikeImage = styled.img`
  width: 80px;
  position: absolute;
  left: 40%;
  bottom: 0;
  animation-name: ${scaling};
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${mediumBreakpoint}) {
    top: 80%;
    left: 60%;
    width: 120px;
  }
  @media all and (min-width: ${fullhd}) {
    left: unset;
    top: 5%;
    right: 5%;
    display: block;
    width: 160px;
  }
`

export const DevicesImage = styled.img`
  width: 80px;
  position: absolute;
  right: 10%;
  bottom: 20%;
`

export const HeaderWrapper = styled.div`
  @media all and (min-width: ${mediumBreakpoint}) {
    display: none;
  }
`
