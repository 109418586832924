import styled from 'styled-components'
import {
  scaling,
  movingBall,
  leftAndRight,
} from '../../../assets/keyframes/keyframes'
import {
  mediumBreakpoint,
  fullhd,
  largeBreakpoint,
  xsmallBreakpoint,
} from '../../../config/css-constants'

export const AboutUsText = styled.h2`
  position: absolute;
  top: -50%;
  left: 5%;
  font-size: 23px;
  letter-spacing: 1px;
  font-family: Poppins, serif;
  @media all and (min-width: ${mediumBreakpoint}) {
    margin-top: 30px;
  }
  @media all and (min-width: ${fullhd}) {
    font-size: 25px;
    top: 0;
    left: unset;
    right: 6%;
  }
`

export const DeviceImage = styled.img`
  width: 120px;
  position: absolute;
  bottom: unset;
  top: -50%;
  right: 5%;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${xsmallBreakpoint}) {
    top: 0;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
  }
  @media all and (min-width: ${fullhd}) {
    bottom: 50%;
    top: unset;
  }
`

export const MobileImage = styled.img`
  position: absolute;
  width: 150px;
  transform: translateX(0);
  animation-fill-mode: forwards;
  animation-name: ${leftAndRight};
  animation-duration: 7s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  z-index: 2;
  @media all and (min-width: ${xsmallBreakpoint}) {
    display: none;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
    left: 70%;
    bottom: 0;
  }
  @media all and (min-width: ${fullhd}) {
    left: 45%;
    top: 54%;
    width: 200px;
  }
`

export const IdeaImage = styled.img`
  position: absolute;
  width: 130px;
  bottom: 90%;
  left: 65%;
  animation-name: ${movingBall};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${mediumBreakpoint}) {
    display: none;
  }
  @media all and (min-width: ${fullhd}) {
    display: block;
    left: unset;
    right: 17%;
    bottom: 20%;
    width: 200px;
  }
`

export const PaperImage = styled.img`
  display: block;
  position: absolute;
  z-index: 2;
  right: 3%;
  bottom: -5%;
  width: 70px;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`
