import styled from 'styled-components'
import {
  fullhd,
  largeBreakpoint,
  mediumBreakpoint,
  xsmallBreakpoint,
} from '../../../config/css-constants'
import { movingBallReverse } from '../../../assets/keyframes/keyframes'
import bgWaveMobile from '../../../images/home/tlo_gorne.svg'

export const BlueCircle = styled.div`
  position: absolute;
  background-color: #188e9f;
  border-radius: 50%;
  left: 58%;
  top: 35%;
  width: 65px;
  height: 65px;
  display: none;
  animation-name: ${movingBallReverse};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${fullhd}) {
    display: block;
  }
`

export const Divider = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-image: none;
  background-color: #387e8d;
  margin-top: 80px;
  padding: 24.62% 0 0;
  margin-bottom: -1px;
  &:before {
    content: '';
    background-image: url(${bgWaveMobile});
    position: absolute;
    height: 53px;
    top: -53px;
    width: 100%;
    background-size: cover;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    background-image: unset;
    background-color: unset;
    &:before {
      display: none;
    }
  }
`

export const WavesImage = styled.img`
  display: none;
  @media all and (min-width: ${mediumBreakpoint}) {
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
  }
`

export const AboutUsText = styled.h2`
  display: none;
  font-size: 23px;
  letter-spacing: 1px;
  position: absolute;
  left: 5%;
  top: 15%;
  @media all and (min-width: ${xsmallBreakpoint}) {
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    margin-top: 30px;
    display: block;
  }
  @media all and (min-width: ${largeBreakpoint}) {
  }
  @media all and (min-width: ${fullhd}) {
    font-size: 25px;
    left: unset;
    top: 80%;
    right: 5%;
  }
`
