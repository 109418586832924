import React from 'react'
import {
  ContactWrapper,
  ContactTitle,
  ContactSection,
  ContactSectionWrapper,
} from './styles'
import { ButtonLink } from '../../UI/button'

const HomePageConstactSection = () => {
  return (
    <ContactSection id="home-contact">
      <ContactSectionWrapper>
        <ContactWrapper>
          <ContactTitle>If you have any questions</ContactTitle>
          <ButtonLink to={'/contact'}>Contact with us</ButtonLink>
        </ContactWrapper>
      </ContactSectionWrapper>
    </ContactSection>
  )
}

export default HomePageConstactSection
