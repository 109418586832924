import styled from 'styled-components'
import {
  mediumBreakpoint,
  largeBreakpoint,
  xsmallBreakpoint,
  fullhd,
} from '../../../config/css-constants'
import { rotate360 } from '../../../assets/keyframes/keyframes'
import { scaling, movingBall } from '../../../assets/keyframes/keyframes'

export const IdeaImage = styled.img`
  position: absolute;
  width: 130px;
  bottom: 15%;
  left: 100%;
  animation-name: ${movingBall};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @media all and (min-width: ${mediumBreakpoint}) {
    bottom: -25%;
  }
`

export const PaperImage = styled.img`
  display: block;
  position: absolute;
  z-index: 2;
  right: 15%;
  bottom: -5%;
  width: 70px;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`

export const Section = styled.section`
  background-color: #3c7f8e;
  position: relative;
  line-height: 0;
`

export const WhyUsHeader = styled.h2`
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 25px;
  text-decoration: none solid rgb(255, 255, 255);
  letter-spacing: 2px;
`

export const SectionWrapper = styled.div`
  line-height: 1.5rem;
`

export const ContactMobileImage = styled.img`
  display: block;
  background: #fff;
  position: absolute;
  top: 0;
  @media all and (min-width: ${xsmallBreakpoint}) {
    display: none;
  }
`

export const OfferWrapper = styled.div`
  position: absolute;
  color: #000;
  display: block;
  z-index: 2;
  width: 30%;
  left: 10%;
  bottom: 0;
  @media all and (min-width: ${xsmallBreakpoint}) {
    bottom: -15%;
    width: 60%;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    bottom: 2%;
    width: 50%;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    font-size: 1.4rem;
    bottom: 5%;
  }
  @media all and (min-width: ${fullhd}) {
    bottom: 10%;
  }
`

export const OfferTitle = styled.p`
  margin-bottom: 30px;
  font-weight: 800;
  text-transform: uppercase;
`

export const OfferBgWrapper = styled.div`
  position: relative;
  padding: 90% 0 0;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    right: 0;
    background-color: #fff;
  }
  @media all and (min-width: ${xsmallBreakpoint}) {
    padding: 56.5% 0 0;
  }
`

export const HelpImage = styled.img`
  transform: scale(0.3);
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-name: ${(props) => (props.isvisible ? rotate360 : '')};
`
