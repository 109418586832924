import React from 'react'
import { BlueCircle, Divider, WavesImage, AboutUsText } from './styles'
import HomePageHeader from '../HomePageHeader/HomePageHeader'
import HomePageComputerAnimation from '../HomePageComputerAnimation/HomePageComputerAnimation'
import HomePageHeaderIcons from '../HomePageHeaderIcons/HomePageHeaderIcons'
import bgWave from '../../../images/home/rysunek.svg'

const HomePageTopSection = () => {
  return (
    <>
      <div className={'header'}>
        <HomePageHeader />
        <AboutUsText>
          <p>
            {' '}
            We are <strong> Deployed</strong>, a software development company
          </p>
          <p> that builds custom digital products for our clients. </p>
        </AboutUsText>
      </div>

      <Divider>
        <WavesImage src={bgWave} alt={''} role={'presentation'} />
        <BlueCircle />
        <HomePageComputerAnimation />
        <div className={'is-hidden-mobile'}>
          <HomePageHeaderIcons />
        </div>
      </Divider>
    </>
  )
}

export default HomePageTopSection
